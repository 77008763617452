import React from 'react'
import styles from './style.module.sass'

export default ({ benefits }) => {
  if (!benefits || benefits.isHide) return null

  return (
    <div className={styles.benefits}>
      <div className={styles.wrap}>
        <div className={styles.column}>
          <h2 className={styles.title}>{benefits.title}</h2>
          <p className={styles.description}>{benefits.description}</p>
        </div>
        <div className={styles.column}>
          <div className={styles.lines}>
            {benefits.lines && benefits.lines.map(line => (
              <div className={styles.item} key={line._key}>
                <div className={styles.itemValue}>{line.value}</div>
                <div className={styles.itemDescription}>{line.description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
