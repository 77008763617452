import React from 'react'
import { sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ benefitsBlocks }) => {
  if (!benefitsBlocks || benefitsBlocks.isHide) return null

  return (
    <div className={styles.benefitsBlocks}>
      <div className={styles.block}>
        <img className={styles.blockBg} src={sanityImageUrl(benefitsBlocks.block1Image)} alt={benefitsBlocks.block1Image?.caption} />
        <div className={styles.content}>
          <div className={styles.top}>
            <p className={styles.block1Title}>{benefitsBlocks.block1Title}</p>
          </div>
          <div className={styles.bottom}>
            <ul className={styles.block1List}>
              {benefitsBlocks.block1List && benefitsBlocks.block1List.map((item, index) => <li key={index}>{item}</li>)}
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.block}>
        <img className={styles.blockBg} src={sanityImageUrl(benefitsBlocks.block2Image)} alt={benefitsBlocks.block2Image?.caption} />
        <div className={styles.content}>
          <div className={styles.top}>
            <img className={styles.stars} src={sanityImageUrl(benefitsBlocks.block2Stars)} alt={benefitsBlocks.block2Stars?.caption} />
            <p className={styles.block2Text}>{benefitsBlocks.block2Text}</p>
          </div>
          <div className={styles.bottom}>
            <p className={styles.block2Customer}>{benefitsBlocks.block2Customer}</p>
            <p className={styles.block2Verified}>{benefitsBlocks.block2Verified}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
