import React from 'react'
import { sanityImageUrl } from '../../../utils/format'
import { Link } from 'gatsby'
import SwiperObj, { FreeMode, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './style.module.sass'

export default ({ protocols }) => {
  if (!protocols || protocols.isHide) return null

  SwiperObj.use([FreeMode, Mousewheel])

  const sliderSettings = {
    spaceBetween: 64,
    slidesPerView: 3,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    className: styles.slider,
    breakpoints: {
      0: {
        slidesPerView: 1.2,
        spaceBetween: 16
      },
      830: {
        slidesPerView: 2.2,
        spaceBetween: 32
      },
      1100: {
        slidesPerView: 3,
        spaceBetween: 64
      }
    },
  }

  return (
    <div className={styles.protocols}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{protocols.title}</h2>
        <p className={styles.description}>{protocols.description}</p>
        <Swiper {...sliderSettings}>
          {protocols.list &&
            protocols.list.map(protocol => (
              <SwiperSlide className={styles.item}>
                <div className={styles.top}>
                  <div className={styles.image}>
                    <img src={sanityImageUrl(protocol.imageSquare)} className={styles.imageSrc} alt={protocol.imageSquare?.caption} />
                    <span className={styles.price}>{protocol.price}/mo</span>
                  </div>
                  <p className={styles.name}>{protocol.name}</p>
                  <p className={styles.info}>{protocol.descriptionShort}</p>
                </div>
                <div className={styles.bottom}>
                  {protocol.learnMoreButtonText && protocol.learnMoreButtonUrl && (
                    <Link to={protocol.learnMoreButtonUrl} className={styles.link}>
                      {protocol.learnMoreButtonText}
                    </Link>
                  )}
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  )
}
