import React from 'react'
import styles from './style.module.sass'

export default ({ howItWorks }) => {
  if (!howItWorks || howItWorks.isHide) return null

  return (
    <div className={styles.howItWorks}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{howItWorks.title}</h2>
        <p className={styles.description}>{howItWorks.description}</p>
        <div className={styles.items}>
          {howItWorks.items && howItWorks.items.map(item => (
            <div className={styles.item} key={item._key}>
              <p className={styles.itemTitle}>
                {item.arrow && item.arrow === 'up' && <span className={styles.arrowUp} />}
                {item.arrow && item.arrow === 'down' && <span className={styles.arrowDown} />}
                {item.title}
              </p>
              <p className={styles.itemDescription}>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
